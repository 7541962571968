import { sortByProperty } from '@/helpers/sort';

export const gridDefaultColDef = {
  resizable: true,
  sortable: true,
};

export function gridColumns(ProfileLink) {
  return [
    {
      headerName: 'Triennium',
      flex: 1,
      autoHeight: true,
      sort: 'desc',
      sortable: false,
      sortIndex: 0,
      comparator: (valueA, valueB) => {
        const valueAPositions = valueA.positions;
        const valueBPositions = valueB.positions;

        valueAPositions.sort((a, b) => b.triennium.number - a.triennium.number);
        valueBPositions.sort((a, b) => b.triennium.number - a.triennium.number);

        return valueAPositions[0].triennium.number - valueBPositions[0].triennium.number;
      },
      valueGetter: (params) => params.data,
      cellRenderer: (params) => {
        const { positions } = params.data;

        positions.sort((a, b) => b.triennium.from - a.triennium.from);

        return positions
          .map((p) => `<div>${p.triennium.from} - ${p.triennium.to}</div>`)
          .join('');
      },
    },
    {
      headerName: 'Role',
      flex: 1,
      sortable: false,
      sort: 'asc',
      autoHeight: true,
      cellRenderer: (params) => {
        const { positions } = params.data;

        return positions
          .map((p) => `<div>${p.name}</div>`)
          .join('');
      },
    },
    {
      headerName: 'Full Name',
      flex: 1,
      sortable: false,
      sort: 'asc',
      sortIndex: 1,
      valueGetter: (params) => params.data,
      cellRenderer: ProfileLink,
      comparator: (valueA, valueB) => sortByProperty(valueA, valueB, 'surname'),
    },
  ];
}
